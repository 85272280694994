@font-face {
  font-family: 'Gellix';
  src: url('https://minhas-fontes.s3.amazonaws.com/Gellix-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('https://minhas-fontes.s3.amazonaws.com/MonumentExtended-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('https://minhas-fontes.s3.amazonaws.com/MonumentExtended-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten';
  src: url('https://minhas-fontes.s3.amazonaws.com/Tungsten-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #0047AB;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-top: 40px;
  padding-bottom: 40px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  max-width: 100%;
  margin-bottom: 20px;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.header-logo img {
  width: auto;
  height: 120px;
}

.header-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-titles p {
  font-family: 'Gellix', sans-serif;
  font-size: 16px;
  margin: 5px 0 0 0;
  color: white;
  max-width: 550px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
  height: 54vh;
  background-color: white;
  border-radius: 25px;
  padding: 15px;
  position: relative;
  font-family: 'Gellix', sans-serif;
  margin-bottom: 20px;
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 8px;
}

.chat-bubble {
  max-width: 70%;
  padding: 12px 18px;
  border-radius: 20px;
  font-size: 16px;
}

.chat-bubble.user {
  background-color: #D4E157;
  color: black;
  align-self: flex-end;
}

.chat-bubble.bot {
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  color: black;
  align-self: flex-start;
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 70%;
  margin-bottom: 10px;
}

.bot-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle;
}

.chat-input {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 25px;
  background-color: #e0e0e0;
  font-family: 'Gellix', sans-serif;
}

.chat-input input {
  flex: 1;
  border: none;
  padding: 8px;
  border-radius: 20px;
  outline: none;
  background-color: #e0e0e0;
  font-size: 16px;
}

.chat-input button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #757575;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 22px;
  transition: background-color 0.3s ease;
}

.chat-input button:hover {
  background-color: #D4E157;
}

/* Botões customizados */
.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  flex-direction: row;
}

.custom-button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Gellix', sans-serif;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-button:hover {
  background-color: white;
  color: #0047AB;
}

.custom-button:hover {
  background-color: white;
  color: #0047AB;
}

/* Footer */
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 15px 0;
  font-family: 'Tungsten', sans-serif;
  font-size: 20px;
}

.footer-content {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-mobile {
  display: none;
}

.footer-left,
.footer-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Tungsten', sans-serif;
  line-height: 120%;
  letter-spacing: -3%;
}

.footer-left .material-icons,
.footer-right .material-icons {
  font-size: 28px;
  margin-right: 8px;
}

.footer-link {
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.footer-left a,
.footer-left a:visited {
  color: white;
  text-decoration: none;
}

.footer-left a:hover {
  color: #f5f5f5;
}

.footer-right a,
.footer-right a:visited {
  color: white;
  text-decoration: none;
}

.footer-right a:hover {
  color: #f5f5f5;
}

.footer-link:hover::after {
  width: 100%;
}

.footer-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.footer-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Tungsten', sans-serif;
  line-height: 120%;
  letter-spacing: -3%;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.footer-icons a {
  color: white;
  transition: color 0.3s ease;
}

.footer-icons a:hover {
  color: #d4e157;
}

.footer-icons svg {
  font-size: 24px;
}

/* Bordas personalizadas apenas no modo desktop */
@media (min-width: 1024px) {
  .top-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; /* Ocupar 100% da largura do viewport */
    height: auto;
    background-image: url('https://images-rita.s3.amazonaws.com/borda+de+cima.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .left-border {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100vh; /* Ocupar 100% da altura do viewport */
    background-image: url('https://images-rita.s3.amazonaws.com/borda+esquerda.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .right-border {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100vh; /* Ocupar 100% da altura do viewport */
    background-image: url('https://images-rita.s3.amazonaws.com/borda+direita.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}

@media (min-width: 1440px) and (min-height: 900px) {
  .top-border {
    width: 100vw;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
  }

  .left-border {
    height: 100vh;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
  }

  .right-border {
    height: 100vh;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
}

@media (max-width: 768px) {
  .header-container {
    padding: 0 10px;
    margin-bottom: 20px;
    width: 90%; 
  }

  .header-logo img {
    height: 60px;
  }

  .header-titles p {
    font-size: 14px;
    text-align: center;
  }

  .chat-container {
    width: 90%;
    padding: 15px;
    margin: 0 auto;
    height: 45vh;
    margin-top: 10px;
  }

  .chat-bubble {
    font-size: 16px;
  }

  .chat-input {
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;
  }

  .chat-input input {
    width: calc(100% - 60px);
    padding: 8px;
  }

  .chat-input button {
    width: 40px;
    height: 40px;
  }

  .footer {
    font-size: 14px;
    margin-top: 80px;
  }
}

@media (max-width: 428px) {
  .header-container {
    padding: 0 10px;
    margin-bottom: 15px;
    width: 90%; 
  }

  .header-logo img {
    height: 50px;
  }

  .header-titles p {
    font-size: 12px;
    text-align: center;
  }

  .chat-container {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    height: 55vh;
    margin-top: 10px;
  }

  .chat-bubble {
    font-size: 14px;
  }

  .chat-input {
    width: 100%;
    padding: 6px 10px;
    box-sizing: border-box;
  }

  .chat-input input {
    width: calc(100% - 55px);
    padding: 6px;
  }

  .chat-input button {
    width: 35px;
    height: 35px;
  }

  .footer {
    font-size: 12px;
    margin-top: 80px;
  }
}

@media (max-width: 390px) {
  .header-container {
    padding: 0 10px;
    margin-bottom: 10px;
    width: 90%; 
  }

  .header-logo img {
    height: 40px;
  }

  .header-titles p {
    font-size: 10px;
    text-align: center;
  }

  .chat-container {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    height: 55vh;
    margin-top: 10px;
  }

  .chat-bubble {
    font-size: 12px;
  }

  .chat-input {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
  }

  .chat-input input {
    width: calc(100% - 50px);
    padding: 5px;
  }

  .chat-input button {
    width: 30px;
    height: 30px;
  }

  .footer {
    font-size: 14px;
    margin-top: 20px;
  }

  /* Para 1024px */
@media (max-width: 1024px) {
  .custom-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .button-container {
    flex-direction: column;
    margin-top: 12px;
    gap: 12px;
  }
}

/* Para 768px */
@media (max-width: 768px) {
  .custom-button {
    font-size: 13px;
    padding: 8px 14px;
  }

  .button-container {
    flex-direction: column;
    margin-top: 12px;
    gap: 12px;
  }
}

/* Para 428px */
@media (max-width: 428px) {
  .custom-button {
    font-size: 12px;
    padding: 7px 12px;
  }

  .button-container {
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 12px;
    margin-right: 12px;
    gap: 12px;
  }
}

/* Para 390px */
@media (max-width: 390px) {
  .custom-button {
    font-size: 11px;
    padding: 6px 10px;
    flex-direction: column;
  }

  .button-container {
    margin-top: 12px;
    gap: 12px;
    align-items: center;
  }
}

}
