.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .loading-spinner .dot {
    width: 8px;
    height: 8px;
    background-color: #757575;
    border-radius: 50%;
    animation: dot-blink 1.2s infinite ease-in-out both;
  }
  
  .loading-spinner .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loading-spinner .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dot-blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  